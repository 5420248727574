<template>
	<!-- 认证中心 -->
	<div class="storeCertEdit el-content">
		<a-form  :label-col="{span:2}" :wrapper-col="{span:8}">
			<div class="sc-form-br">认证资料</div>
			<a-form-item label="认证名称" name="cert_name">
				<a-input v-model:value="form.cert_name"></a-input>
			</a-form-item>
			<a-form-item label="认证金额">
				<a-input v-model:value="form.cert_amount" addon-after="元"> </a-input>
			</a-form-item>
			<a-form-item>
				<template #label>
					<span>认证图标</span>
					<a-tooltip title="认证成功后,认证图标作为身份标识展示;图标尺寸1:1,图标尺寸不得小于96*96px">
						<i class="el-icon-info"></i>
					</a-tooltip>
				</template>
				<kd-img-select :src="form.cert_logo" @success="(url)=>{ form.cert_logo = url }"></kd-img-select>
			</a-form-item>
			<a-form-item label="组合图标颜色">
				<kd-color v-model="form.color"></kd-color>
			</a-form-item>
			<a-form-item label="认证描述">
				<a-input v-model:value="form.cert_desc"></a-input>
			</a-form-item>
			<a-form-item label="状态">
			    <a-radio-group v-model:value="form.status">
					<a-radio :value="1">启用</a-radio>
					<a-radio :value="0">禁用</a-radio>
			    </a-radio-group>
			</a-form-item>
			<a-form-item label="选择表单" v-for="(item,index) in form.cert_form.form" :key="item.sign" >
				<a-space>
					<a-select v-model:value="item.input" placeholder="请选择" style="width: 120px;margin-right: 10px;">
					    <a-select-option value="text">文本类型</a-select-option>
					    <a-select-option value="image">图片类型</a-select-option>
					</a-select>
					<template v-if="item.input == 'text'">
						<a-input placeholder="认证标题" v-model:value="item.label"></a-input>
						<a-input placeholder="认证文本内容" v-model:value="item.placeholder"></a-input>
					</template>
					<template v-else>
						<a-input placeholder="认证标题" v-model:value="item.label"></a-input>
						<img class="sc-form-item-img" src="@/assets/img/default.png" alt="">
					</template>
					<div class="sc-form-item-icon">
						<i class="ri-indeterminate-circle-fill ri-2x scfi-color2" @click="reduceForm(index)" v-if="index!=0"></i>
						<i class="ri-add-circle-fill ri-2x scfi-color1" @click="addForm" v-if="index == form.cert_form.form.length-1"></i>
					</div>
				</a-space>
			</a-form-item>
			<div class="sc-form-br">认证协议</div>
			<a-form-item label="认证协议标题">
				<a-input v-model:value="form.cert_form.treaty.name"></a-input>
			</a-form-item>
			<a-form-item label="认证协议内容">
				<tinymce-editor v-model="form.cert_form.treaty.cont"></tinymce-editor>
			</a-form-item>
			
			<a-form-item :wrapper-col="{offset:2}">
				<a-space>
					<a-button type="primary" @click="saveSubmit" >保存</a-button>
					<a-button @click="$router.go(-1)">返回</a-button>
				</a-space>
			</a-form-item>
		</a-form>
	</div>
</template>

<script>
import kdColor from "@/components/public/kd-color.vue";
import shopModel from "../../../../api/addons/shop.js"
import { reactive, toRefs } from 'vue';
import { useRoute } from 'vue-router';
export default{
	components:{
		kdColor
	},
	setup(){
		const _d = reactive({
			form:{
				cert_name:'',
				cert_logo:'',
				cert_amount:'',
				cert_desc:'',
				color:'',
				cert_form:{
					form:[
						{
							sign:new Date().getTime(), // Hash 标识
				            input:"text",     // 插件类型 text|redio|image|identity(与图片不同的是需要提交正反面)
				            value:"",
				            label:"",
				            placeholder:"",
				            option:[]
						}
					],
					treaty:{ name:"",cont:"" },
				},
				status:1,
			},
		})
		const opt = useRoute().query
		if( opt.template_id ) shopModel.getShopCertTemplate(opt.template_id,res=>{
			_d.form.cert_name = res.cert_name
			_d.form.cert_logo = res.cert_logo
			_d.form.cert_amount = res.cert_amount
			_d.form.cert_desc = res.cert_desc
			_d.form.cert_form = res.cert_form
			_d.form.status = res.status
			_d.form.color = res.color
		})

		if( opt.cert_id ) shopModel.getShopCertDetail(opt.cert_id,res=>{
			_d.form.cert_id = res.cert_id
			_d.form.cert_name = res.cert_name
			_d.form.cert_logo = res.cert_logo
			_d.form.cert_amount = res.cert_amount
			_d.form.cert_desc = res.cert_desc
			_d.form.cert_form = res.cert_form
			_d.form.status = res.status
			_d.form.color = res.color
		})

		function addForm(){
			_d.form.cert_form.form.push({
				sign:new Date().getTime(), // Hash 标识
	            input:"text",     // 插件类型 text|redio|image|identity(与图片不同的是需要提交正反面)
	            value:"",
	            label:"",
	            placeholder:"",
	            option:[]
			})
		}

		const reduceForm = (index)=>_d.form.cert_form.form.splice(index,1)

		const saveSubmit = ()=>shopModel.addOrEditShopCert(_d.form)

		return{
			...toRefs(_d),
			addForm,
			reduceForm,
			saveSubmit
		}
	}
}
</script>

<style lang="scss">
	.sc-form-br{
		width: 70%;
		height: 40px;
		border-bottom: 1px solid #f4f4f4;
		margin-left: 53px;
		font-size: 14px;
		line-height: 40px;
		margin-bottom: 20px;
		color: #606266;
	}
	.sc-form-treaty-icon{
		position: absolute;
		cursor: pointer;
	}
	.sc-form-item-img{
		width: 100px;
		height: 100px;
	}
	
	.scfi-color1{
		color: #6495ed;
	}
	.scfi-color2{
		color: #ff0000;
	}
</style>
